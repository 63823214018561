// src/pages/About.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/main.css';

function About() {
  return (
    <div>
      <Header />
      <main>
        <h2>À propos de Handiaide</h2>
        <p>Handiaide est une plateforme dédiée à fournir des informations centralisées sur les divers aspects du handicap. Nous croyons en l'égalité et l'inclusion, et notre mission est de rendre la vie plus facile pour les personnes en situation de handicap en leur fournissant les ressources dont elles ont besoin.</p>
      </main>
      <Footer />
    </div>
  );
}

export default About;
