// src/pages/Terms.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/main.css';

function Terms() {
  return (
    <div>
      <Header />
      <main>
        <h2>Conditions Générales d'Utilisation</h2>
        <h3>Introduction</h3>
        <p>Bienvenue sur Handiaide. En utilisant notre site web, vous acceptez les présentes conditions générales d'utilisation. Veuillez les lire attentivement.</p>
        <h3>Utilisation du Site</h3>
        <p>Vous vous engagez à utiliser le site conformément aux lois et règlements en vigueur. Toute utilisation abusive ou non autorisée du site est strictement interdite.</p>
        <h3>Propriété Intellectuelle</h3>
        <p>Tous les contenus présents sur le site, y compris les textes, images, logos et codes, sont protégés par les droits d'auteur. Vous ne pouvez pas les reproduire sans autorisation préalable.</p>
        <h3>Limitation de Responsabilité</h3>
        <p>Handiaide ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation ou de l'incapacité à utiliser le site.</p>
        <h3>Données Personnelles</h3>
        <p>Nous nous engageons à protéger la vie privée de nos utilisateurs. Les informations collectées sur le site seront utilisées conformément à notre politique de confidentialité.</p>
        <h3>Cookies</h3>
        <p>Nous utilisons des cookies pour améliorer l'expérience utilisateur sur notre site. En continuant à utiliser le site, vous acceptez notre utilisation des cookies.</p>
        <h3>Liens Externes</h3>
        <p>Notre site peut contenir des liens vers des sites externes. Handiaide ne peut être tenu responsable du contenu de ces sites externes.</p>
        <h3>Modifications des Conditions</h3>
        <p>Nous nous réservons le droit de modifier les présentes conditions à tout moment. Les modifications entreront en vigueur dès leur publication sur le site.</p>
        <h3>Mentions légales</h3>
        <p>Nom de l'entreprise : Nu-âge d'or<br/>
        Adresse du siège social : 58 rue himmerich 67000 Strasbourg, France<br/>
        Numéro de SIRET : 84912286600015<br/>
        Directeur de publication : Gokhan GURBUZ<br/>
        Coordonnées de l'hébergeur : LWS (www.lws.fr)</p>
        <h3>Contact</h3>
        <p>Pour toute question concernant ces conditions, veuillez nous contacter à l'adresse suivante : contact@handiaide.com.</p>
      </main>
      <Footer />
    </div>
  );
}

export default Terms;
