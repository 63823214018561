import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/main.css';

function Header() {
  return (
    <header>
      <h1>Handiaide</h1>
      <nav>
        <Link to="/">Accueil</Link>
        <Link to="/about">À propos</Link>
        <Link to="/terms">Conditions Générales</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/faq">FAQ</Link>
        <Link to="/news">Veille</Link> {/* Nouvelle route pour la veille */}
      </nav>
    </header>
  );
}

export default Header;
