// src/pages/Contact.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ContactForm from '../components/ContactForm';
import '../styles/main.css';

function Contact() {
  return (
    <div>
      <Header />
      <main>
        <h2>Contactez-nous</h2>
        <p>Pour toute question ou demande d'information, n'hésitez pas à nous contacter :</p>
        <p>Email : contact@handiaide.com<br/>
        Téléphone : +33 7 78 55 75 25<br/>
        Adresse : 67000 Strasbourg, France</p>
        <ContactForm />
      </main>
      <Footer />
    </div>
  );
}

export default Contact;
