// src/pages/FAQ.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/main.css';

function FAQ() {
  return (
    <div>
      <Header />
      <main>
        <h2>Foire Aux Questions (FAQ)</h2>
        <h3>Q : Qu'est-ce que Handiaide ?</h3>
        <p>R : Handiaide est une plateforme dédiée à fournir des informations centralisées sur les divers aspects du handicap.</p>
        <h3>Q : Comment puis-je contacter le support ?</h3>
        <p>R : Vous pouvez nous contacter par email à contact@handiaide.com ou par téléphone au +33 7 78 55 75 25.</p>
        <h3>Q : Quels services offrez-vous ?</h3>
        <p>R : Nous offrons des ressources et des informations pour aider les personnes en situation de handicap et leurs proches.</p>
        <h3>Q : Quels types de handicap sont couverts sur votre plateforme ?</h3>
        <p>R : Nous couvrons une large gamme de handicaps, y compris les handicaps physiques, sensoriels, mentaux et cognitifs.</p>
        <h3>Q : Comment puis-je contribuer à Handiaide ?</h3>
        <p>R : Vous pouvez contribuer en partageant des informations, des ressources et en faisant des dons pour soutenir notre mission.</p>
        <h3>Q : Vos services sont-ils gratuits ?</h3>
        <p>R : Oui, l'accès aux informations et ressources sur notre site est gratuit. Cependant, certaines fonctionnalités futures pourraient être payantes.</p>
        <h3>Q : Comment garantissez-vous la qualité des informations fournies ?</h3>
        <p>R : Nous collaborons avec des experts et des associations spécialisées pour vérifier et mettre à jour régulièrement les informations.</p>
      </main>
      <Footer />
    </div>
  );
}

export default FAQ;
