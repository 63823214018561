// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../styles/main.css';

function Home() {
  return (
    <div>
      <Header />
      <main>
        <h2>Accueil</h2>
        <h2>Bienvenue sur Handiaide</h2>
        <p>L'univers du handicap centralisé pour vous.</p>
        <p>Ceci est le début de votre site web React ! Notre objectif est de fournir des ressources et des informations pour aider les personnes en situation de handicap et leurs proches.</p>
      </main>
      <Footer />
    </div>
  );
}

export default Home;
